import React from 'react';
import {SpinnerDiamond} from 'spinners-react';

import './App.css';
import LandingLogo from "./components/LandingLogo";
import {Grid} from "@mui/material";

export class App extends React.Component<{}> {
    render() {
        return (
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{minHeight: '100vh'}}
            >
                <Grid item xs={12}>
                    <LandingLogo/>
                </Grid>
                <Grid item xs={12}>
                    <SpinnerDiamond size={50} thickness={100} speed={100} color="#36ad47"
                                    secondaryColor="rgba(0, 0, 0, 0.44)"/>
                </Grid>
            </Grid>
        );
    }
}

export default App;