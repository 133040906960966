import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import Logo from "../assets/svg/Logo";

import './LandingLogo.css'

export class LandingLogo extends React.Component<{}> {
    render() {
        return (
            <div className="LandingLogo">
                <SvgIcon viewBox={Logo.viewBox} fontSize={"large"}>
                    {Logo.icon}
                </SvgIcon>
            </div>
        );
    }
}

export default LandingLogo;